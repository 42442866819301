import React from 'react'
import * as Icon from 'react-feather';

import '../../styles/features.scss';

const Features = () => {
    return (
      <section className="features-area ptb-80">
        <div className="container">
          <div className="section-title">
            <h2>What our solution can do?</h2>
            <div className="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
          </div>

          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="single-features">
                <div className="icon">
                  <Icon.Settings />
                </div>
                <h3>Production Control</h3>
                <p>
                  Effectively Manage from raw material processing, packaging, warehouse, transportation to retrails.
                </p>
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="single-features">
                <div className="icon">
                  <Icon.Mail />
                </div>
                <h3>Smart Warehouse</h3>
                <p>
                  Improve warehouse operation efficiency and accuracy, provide real-time visual warehouse management experience.
                </p>
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="single-features">
                <div className="icon bg-c679e3">
                  <Icon.Grid />
                </div>
                <h3>Smart Retail</h3>
                <p>
                  Automate stock intake and dispatch, and sale process, increase efficiency
                </p>
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="single-features">
                <div className="icon bg-c679e3">
                  <Icon.Info />
                </div>
                <h3>Provenance Tracking</h3>
                <p>
                  Control and record all the data in the supply chain from the source to end customer
                </p>
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="single-features">
                <div className="icon bg-eb6b3d">
                  <Icon.Monitor />
                </div>
                <h3>Anti-Counterfeit</h3>
                <p>
                  Use the features of RFID tag to prevent counterfeit
                </p>
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="single-features">
                <div className="icon bg-eb6b3d">
                  <Icon.Mail />
                </div>
                <h3>Accurate Real-Time Data</h3>
                <p>
                  Real-Time data collections in the supply chain, provide in-depth data analysis
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
}

export default Features;
