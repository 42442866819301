import React from "react"

import Layout from '../components/layout'
import SEO from "../components/seo"
import Banner from '../components/index/banner';
import Features from '../components/index/features';
import Stats from '../components/index/stats';
import Cta from '../components/index/cta';
import Industries from '../components/index/industries';
import Footer from '../components/footer';

const Index = () => (
  <Layout>
    <SEO title="Home" />
    <Banner />
    <Features />
    <Cta />
    <Industries />
    <Stats />
    <Footer />
  </Layout>
)

export default Index;
