import React from 'react';
import { Link } from 'gatsby';

import '../../styles/cta.scss';

const Cta = () => {
  return (
    <section className="iot-cta-area bg-0f054b">
      <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="cta-iot-img">
                <img 
                  src={require('../../images/cta-shape2.png')} alt="cta image" 
                />
              </div>
            </div>

            <div className="col-lg-6">
              <div className="cta-iot-content">
                <h3>Solution for your business and industry</h3>
                <p>
                  Every industry and business has different needs and requirements. We have extensive experience across many industries and ability to create a customised solution tailored to your business needs.
                </p>
                <Link to="#">
                    <a className="btn btn-primary">Contact Us</a>
                </Link>
              </div>
            </div>
          </div>
      </div>

      <div className="circle-box"><img src={require('../../images/circle.png')} alt="image" /></div>
      <div className="cta-shape"><img src={require('../../images/cta-shape.png')} alt="image" /></div>
  </section>
  )
}

export default Cta;
