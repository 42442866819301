import React from 'react';
import { Link } from 'gatsby';
import * as Icon from 'react-feather';

import '../../styles/industries.scss';

function Industries(){

  function LinkToIndustriesPage() {
    return (
      <Link to="/industries">
        <a><Icon.ArrowRight /></a>
      </Link>
    )
  }

  return (
    <section className="iot-services-area ptb-80">
      <div className="container">
        <div className="section-title">
          <h2> Industry-Focused RFID Solutions</h2>
          <div className="bar"></div>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
          
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="industry-box bg1">
              <div className="icon">
                <i className="flaticon-software"></i>
              </div>
              <h3>Retail <br /> </h3>
              <p>
                Fast and frequency stocktake are now possible for 99%+ accuracy enabling omnichannel
              </p>
                {LinkToIndustriesPage()}
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="industry-box bg2">
              <div className="icon">
                <i className="flaticon-gear"></i>
              </div>
              <h3>Transport & Logistic</h3>
              <p>
                Streamline operations with visibility of the entire supply chain from source to distribution to end customer
              </p>
              {LinkToIndustriesPage()}
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="industry-box bg3">
              <div className="icon">
                <i className="flaticon-skyline"></i>
              </div>
              <h3>Food & Beverage</h3>
              <p>
                Improve efficiency and increase visibility across the entire food supply chain with RFID solutions that confirm provenance and sustainability
              </p>
              {LinkToIndustriesPage()}
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="industry-box bg4">
              <div className="icon">
                <i className="flaticon-car-insurance"></i>
              </div>
              <h3>Arts & Valuable Goods</h3>
              <p>
                Undertake faster and more accurate audits to ensure valuable goods are where they should be with massive reductions in labour cost
              </p>
              {LinkToIndustriesPage()}
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="industry-box bg5">
              <div className="icon">
                <i className="flaticon-factory"></i>
              </div>
              <h3>Commercial Laundries</h3>
              <p>
                Efficiently manage linen and other assets, both fixed and moving, to reduce losses and avoid wasteful replacement of stock
              </p>
              {LinkToIndustriesPage()}
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="industry-box bg6">
              <div className="icon">
                <i className="flaticon-chip"></i>
              </div>
              <h3>Healthcare</h3>
              <p>
                Complete visibility of medical equipments from the warehouse to the operation theatre improves efficiency and allows staff to focus on patient care.
              </p>
              {LinkToIndustriesPage()}
            </div>
          </div>
        </div>
      </div>
  </section>
  )
}

export default Industries;
