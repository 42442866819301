import React from 'react';
import { Link } from 'gatsby';

import "../../styles/stats.scss";

function Stats() {
  return (
    <section className="funfacts-area ptb-80">
      <div className="container">
        <div className="section-title">
            <h2>We always try to understand users expectation</h2>
            <div className="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div className="row">
            <div className="col-lg-3 col-md-3 col-6">
                <div className="funfact">
                    <h3>
                      5000K
                    </h3>
                    <p>Product Applied</p>
                </div>
            </div>

            <div className="col-lg-3 col-md-3 col-6">
                <div className="funfact">
                    <h3>
                      87
                    </h3>
                    <p>Applications</p>
                </div>
            </div>

            <div className="col-lg-3 col-md-3 col-6">
                <div className="funfact">
                    <h3>
                      50+
                    </h3>
                    <p>Team</p>
                </div>
            </div>

            <div className="col-lg-3 col-md-3 col-6">
                <div className="funfact">
                    <h3>
                      28+
                    </h3>
                    <p>Partners</p>
                </div>
            </div>
        </div>

        <div className="contact-cta-box">
            <h3>Have any question about us?</h3>
            <p>Don't hesitate to contact us</p>
            <Link href="#">
                <a className="btn btn-primary">Contact Us</a>
            </Link>
            
        </div>

        <div className="map-bg">
            <img src={require("../../images/map.png")} alt="map" />
        </div>
      </div>
    </section>
  )
}

export default Stats;